<template>
  <div class="bkColor">
    <headTitle :title="title"></headTitle>
    <van-search v-model="value" input-align="center" placeholder="请输入搜索关键词" shape="round" show-action @search="onSearch"
      @cancel="onCancel" style="margin-bottom: 2vh" />
    <div class="search">
      <div>状态:</div>
      <div style="
          display: flex;
          justify-content: space-around;
          align-items: center;
        ">
        <van-dropdown-menu>
          <van-dropdown-item v-model="queryFrom.state" :options="stateOption" @change="changePjState" />
        </van-dropdown-menu>
        <div class="searchTitle">项目年份:</div>
        <van-dropdown-menu>
          <van-dropdown-item v-model="queryFrom.year" :options="yearOption" @change="changeYear" />
        </van-dropdown-menu>
        <div class="searchTitle">支付:</div>
        <van-dropdown-menu>
          <van-dropdown-item v-model="queryFrom.isSettle" :options="isSettleOption" @change="changeIsSettle" />
        </van-dropdown-menu>
        <div class="searchTitle">归属:</div>
        <van-dropdown-menu>
          <van-dropdown-item v-model="queryFrom.belong" :options="belongOption" @change="changeBelong" />
        </van-dropdown-menu>
      </div>
    </div>
    <div class="list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
          finished-text="没有更多了" @load="getreimbursementList">
          <div class="listCell" v-for="item in list" :key="item.id" @mouseup="up($event)" @mousedown="down($event)"
            @click="goDetails(item.id)">
            <van-swipe-cell :disabled="!powerList.includes('2') ||
              item.state !== 1 ||
              item.isSettle !== 0
              ">
              <div class="cellName">
                {{ item.projectName ? item.projectName + '项目报销' : item.userName + '的非项目报销' }}
                <div class="status">
                  <span :class="{
                    blue: item.state == 0,
                    green: item.state == 1,
                    red: item.state == 2,
                  }">
                    {{ stateText(item.state) }}</span><br />
                  <span :class="{
                    blue: item.isSettle == 0,
                    green: item.isSettle == 1,
                    red: item.isSettle == 2,
                  }">{{ settlement(item.isSettle) }}</span>
                </div>
              </div>
              <div class="cellText">流水号：{{ item.reimbursementNo }}</div>
              <div class="cellText">
                报销备注：
                <div style="width: 40%; display: inline-block; vertical-align: top">
                  {{ item.reason }}
                </div>
              </div>
              <div class="cellText">报销人：{{ item.userName }}</div>
              <div class="cellText">报销金额：{{ item.totalPrice }}元</div>
              <div class="cellText">报销发起日期：{{ item.createTime }}</div>
              <template #right>
                <van-button color="#CCCCCC" native-type="button" @click="finalConfirmation(item.id, 2)">财务拒绝</van-button>
                <van-button type="info" native-type="button" @click="finalConfirmation(item.id, 1)">财务确认</van-button>
              </template>
            </van-swipe-cell>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="addBtn">
      <img src="../assets/icon/xz_icon.png" alt="" @click="add" />
    </div>
    <div class="exportBtn" @click="exportFile" v-show="powerList.includes('2') || powerList.includes('1')">
      导出
    </div>
    <div class="importBtn" @click="importFile" v-show="powerList.includes('2') || powerList.includes('1')">
      导入
    </div>
  </div>
</template>
  
<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import {
  Search,
  Toast,
  List,
  PullRefresh,
  Cell,
  SwipeCell,
  Button,
  Dialog,
} from "vant";

import { projectStatusText, settlementText, yearOption } from "../units/units";
import {
  reimbursementList,
  reimbursementUpdateState,
  reimbursementExport,
  // reimbursementImportData
} from "../api/reimbursement";

import { getToken } from "../units/auth";
Vue.use(Search);
Vue.use(Toast);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Cell);
Vue.use(SwipeCell);
Vue.use(Button);
Vue.use(Dialog);
export default {
  name: "pjReimbursement",
  components: {
    headTitle,
  },
  data() {
    return {
      title: "项目报销列表",
      stateOption: [
        { text: "全部", value: null },
        { text: "审核中", value: 0 },
        { text: "审核通过", value: 1 },
        { text: "审核不通过", value: 2 },
      ],
      yearOption: yearOption,
      isSettleOption: [
        { text: "全部", value: null },
        { text: "未支付", value: 0 },
        { text: "已支付", value: 1 },
        { text: "拒绝支付", value: 2 },
      ],
      belongOption: [
        { text: "全部", value: null },
        { text: "大美", value: "1" },
        { text: "中创", value: "2" },
        { text: "广云仓", value: "3" },
        { text: "大丰年", value: "4" },
      ],
      value: "",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      pageX: 0,
      pageY: 0,
      isDrag: false,
      queryFrom: {
        year: null,
        state: null,
        // additional: 0,
        vague: "",
        pageSize: 10,
        pageNum: 1,
        isSettle: null,
        belong: null,
      },
      powerList: [], //value 1总经理 2财务 3行政
      userId: "",
      equipment: false
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from.path);
    if (from.path == "/newPjReimbursement") {
      next((vm) => {
        vm.onCancel();
      });
    }
    next();
  },
  mounted() {
    this.getUserId();
    this.getPowerList();
    this.isMobile()
  },
  methods: {
    //获取权限渲染页面
    getPowerList() {
      this.powerList = JSON.parse(getToken("powerList") || "[]");
    },
    getUserId() {
      this.userId = getToken("userId");
    },
    getreimbursementList() {
      this.loading = true;
      reimbursementList(this.queryFrom).then((res) => {
        this.list =
          this.queryFrom.pageNum == 1
            ? res.data.rows
            : this.list.concat(res.data.rows);
        this.refreshing = false;
        this.loading = false;
        this.queryFrom.pageNum = this.queryFrom.pageNum + 1;
        if (
          res.data.total <=
          (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize
        ) {
          this.finished = true;
        }
      });
    },
    //财务是否结算
    settlement(f) {
      return settlementText(f);
    },
    stateText(t) {
      return projectStatusText(t);
    },
    changePjState(val) {
      this.queryFrom.state = val;
      this.onRefresh();
    },
    changeYear(val) {
      this.queryFrom.year = val;
      this.onRefresh();
    },
    changeIsSettle(val) {
      this.queryFrom.isSettleOption = val;
      this.onRefresh();
    },
    changeBelong(val) {
      this.queryFrom.belong = val;
      this.onRefresh();
    },
    onSearch(val) {
      this.queryFrom.vague = val;
      this.onRefresh();
      // this.$router.push({ path: '/preview', query: { url: val } })
    },
    //搜索框取消
    onCancel() {
      this.queryFrom.vague = "";
      this.queryFrom.state = null;
      this.queryFrom.isSettle = null;
      this.queryFrom.belong = null;
      this.onRefresh();
    },
    //下拉刷新
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.queryFrom.pageNum = 1;
      this.list = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.getreimbursementList();
    },
    add() {
      this.$router.push({
        path: "/newPjReimbursement",
      });
    },
    exportFile() {
      if (!this.equipment) {
        Toast('此功能目前只能电脑端使用！')
        return
      }
      reimbursementExport(this.queryFrom).then(res => {
        var blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if ('download' in document.createElement('a')) {
          // 非IE下载
          var elink = document.createElement('a');
          elink.download = '报销列表';
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, '报销列表');
        }

      })
    },
    importFile() {
      if (!this.equipment) {
        Toast('此功能目前只能电脑端使用！')
        return
      }
      this.$router.push({
        path: '/fileImport', query: { title: '报销', routerPath: "/pjReimbursement" }
      })
    },
    //财务最后确认
    finalConfirmation(id, val) {
      Dialog.confirm({
        message: `是否${val == 1 ? "确认" : "拒绝"}该报销`,
        confirmButtonColor: "#0092fe",
      }).then(() => {
        reimbursementUpdateState(id, val).then((res) => {
          if (res.data.code == 200) {
            Toast.success("完成操作");
            this.onRefresh();
          }
        });
      });
    },
    goDetails(id) {
      if (this.isDrag) {
        return;
      }
      this.$router.push({
        path: "/reimbursementDetails",
        query: { id: id },
      });
    },
    up(event) {
      if (this.pageX !== event.pageX) {
        this.isDrag = true;
      } else {
        this.isDrag = false;
      }
    },
    down(event) {
      this.pageX = event.pageX;
      this.pageY = event.pageY;
    },
    // delCell(id) {
    //   Dialog.confirm({
    //     message: `确定撤销？`,
    //   }).then(() => {
    //     reimbursementDeltel(id).then(res => {
    //       if (res.data.code == 200) {
    //         this.onRefresh()
    //       }
    //     })
    //   });
    // }
    isMobile() {
      this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
    }
  },
};
</script>
  
<style lang="less" scoped>
.blue {
  color: #1989fa;
}

.red {
  color: #e86f61;
}

.green {
  color: #00ab45;
}

.bkColor {
  width: 100vw;
  min-height: 100vh;
  background: #f6f6f6;
  position: relative;
}

.search {
  width: 100vw;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  background: #fff;
  font-size: 13px;
  line-height: 8vh;
}

.searchTitle {
  margin-left: 2vw;
}

/deep/ .van-dropdown-menu__title {
  padding: 0 8px 0 0;
}

/deep/ .van-ellipsis {
  font-size: 13px;
}

.list {
  margin-top: 2vh;
}

.listCell {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 5vw 1vh;
  background: #fff;
  text-align: left;
}

.cellName {
  width: 90%;
  font-size: 1rem;
  font-weight: 600;
  border-top: 2px solid #f6f6f6;
  padding-top: 1vh;
  padding-bottom: 1vh;

  .status {
    float: right;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: right;
  }
}

/deep/ .van-list :first-of-type.listCell .cellName {
  border-top: unset;
}

.cellText {
  width: 70%;
  color: #797979;
  margin-bottom: 0.5vh;
  font-size: 0.7rem;
  word-break: break-all;
  line-height: 1;
}

.addBtn {
  width: 55px;
  height: 55px;
  position: fixed;
  top: 77%;
  left: 77%;
  cursor: pointer;

  img {
    width: 55px;
    height: 55px;
  }
}

.exportBtn {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 55%;
  left: 77.3%;
  background: #3495FC;
  border-radius: 50%;
  line-height: 50px;
  color: #fff;
  box-shadow: 0px 6px 8px 0px rgba(122, 117, 117, 0.39);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.importBtn {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 66%;
  left: 77.3%;
  background: #3495FC;
  border-radius: 50%;
  line-height: 50px;
  color: #fff;
  box-shadow: 0px 6px 8px 0px rgba(122, 117, 117, 0.39);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/deep/ .van-button {
  height: 100%;
}
</style>